@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'MontserratSemiBold';
  src: local('MontserratSemiBold'),
    url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'MontserratExtraBold';
  src: local('MontserratExtraBold'),
    url('./assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'MontserratMedium';
  src: local('MontserratMedium'),
    url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'),
    url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0px;
}

.font-extrabold {
  font-family: 'MontserratExtraBold', sans-serif !important;
}

.font-bold {
  font-family: 'MontserratBold', sans-serif !important;
}

.font-medium {
  font-family: 'MontserratMedium', sans-serif !important;
}

.font-semibold {
  font-family: 'MontserratSemiBold', sans-serif !important;
}

.word-break {
  word-break: break-word;
}

.underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.text-align-center {
  text-align: center;
}

.fs-80 {
  font-size: 80px;
}

.fs-50 {
  font-size: 50px;
}

.fs-35 {
  font-size: 35px;
}

.fs-25 {
  font-size: 25px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.padding-30 {
  padding: 30px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-55 {
  margin-top: 55px;
}

.width-100pc {
  width: 100%;
}

.width-50 {
  width: 50px;
}

.display-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.align-items-center {
  align-items: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.gap-35 {
  gap: 35px;
}

/* NOTE TOGGLE */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  border: 1px solid #bababa;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: #1233b5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

/* NOTE Radio Button */
/* The container */
.container {
  display: block;
  position: absolute;
  right: 50px;
  top: 15px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 34px;
  width: 34px;
  background-color: #ffffff00;
  border: 1px solid #bababa;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  border: 1px solid #1233b5;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 7px;
  left: 7px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #1233b5;
}

/* NOTE Media Queries */
@media (max-width: 800px) {
  .mobile-column {
    flex-direction: column;
    gap: 30px;
  }
  .mobile-text-center {
    text-align: center;
  }
  .mobile-margin-auto {
    margin: auto;
  }
  .mobile-hide {
    display: none;
  }
}
